/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { SyntheticEvent, useState } from 'react';
import { Button } from 'primereact/button';
import { Slider, SliderValueType } from 'primereact/slider';
import { TBasicFilterProps } from '../cells/TableHeadCell';

type RangeFilterProp = {
  step?: number
  range: [number, number]
}

export const RangeFilter = ({ filter, closeFilterPanel }: TBasicFilterProps & { filter?: RangeFilterProp }) => {
  if (!Array.isArray(filter?.range) || filter?.range.length !== 2) {
    throw new Error('Property "range" not defined in the schema or defined incorrectly')
  }
  const [value, setValue] = useState<SliderValueType>([filter?.range?.[0], filter?.range?.[1]])
  const submitFormFilter = (e: SyntheticEvent) => {
    e.preventDefault()
    closeFilterPanel(e)
  }

  return (
    <form onSubmit={submitFormFilter}>
      <Slider
        value={value}
        range={true}
        step={filter.step || 1}
        min={filter.range[0]}
        max={filter.range[1]}
        style={{ height: 'calc(5rem / var(--bfs))' }}
        onChange={(e) => setValue(e.value)}
      />
      <div css={rangeFilterFooter}>
        <div>
          {
            Array.isArray(value) ?
              `${value[0].toLocaleString('ru-RU')} - ${value[1].toLocaleString('ru-RU')}` :
              value.toLocaleString('ru-RU')
          }
        </div>
        <Button
          type="submit"
          icon="pi pi-search"
        />
      </div>
    </form>
  )
}

const rangeFilterFooter = css`
  margin-top: calc(16rem / var(--bfs));
  display: flex;
  justify-content: space-between;
  align-items: center;

  .p-button {
    border-radius: var(--border-radius);
    margin: 0;
    width: calc(40rem / var(--bfs));
    height: calc(40rem / var(--bfs));
    color: var(--text-color-active);
  }
`
