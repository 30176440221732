import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { TWidgetProps } from '../types'
import { useObserver } from './useObserver';
import { name } from '../../package.json';

interface IFiltersContext {
  filters: TWidgetProps['filters'];
  sorting: TWidgetProps['sorting'];
  setFilters: React.Dispatch<React.SetStateAction<TWidgetProps['filters']>>;
  setSorting: React.Dispatch<React.SetStateAction<TWidgetProps['sorting']>>;
}

interface IFiltersConfig {
  filters: TWidgetProps['filters'];
  sorting: TWidgetProps['sorting'];
}

interface FilterProviderProps {
  config: IFiltersConfig;
  children: ReactNode;
}

const FiltersContext = React.createContext<IFiltersContext>({
  filters: new Map(),
  sorting: new Map(),
  setFilters: () => {},
  setSorting: () => {},
});

export const useFilters = () => useContext(FiltersContext);

export const FiltersProvider = ({ config, children }: FilterProviderProps) => {
  const [filters, setFilters] = useState(config.filters)
  const [sorting, setSorting] = useState<TWidgetProps['sorting']>(config.sorting)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const { eventBus, eventBusID } = useObserver()

  const resetFilters = () => {
    setFilters(new Map())
    setSorting(new Map())
  }

  useEffect(() => {
    if (eventBusID) {
      eventBus.subscribe(eventBusID, 'resetFilters', resetFilters)
      setIsFirstRender(false)
    }

    return () => {
      eventBusID && eventBus.unsubscribe(eventBusID, 'resetFilters')
    }
  }, [])

  useEffect(() => {
    if (!isFirstRender && eventBusID) {
      eventBus.notify(eventBusID, 'sortingApplied', {
        publisher: name,
        occurrenceTime: Date.now(),
        data: sorting
      })
    }
  }, [sorting])

  return (
    <FiltersContext.Provider value={{ filters, setFilters, sorting, setSorting }}>
      {children}
    </FiltersContext.Provider>
  )
}
