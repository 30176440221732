/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react'
import { Fragment, useEffect, useMemo, useState } from 'react'
import { createPortal } from 'react-dom'
import { TDetailsCell, TMountPortalProps, TTableRow } from '../types'
import { PublishPayload } from '@netvision/lib-types-frontend'
import { Button } from 'primereact/button'
import { ConfirmDialog } from 'primereact/confirmdialog'
import { useLocale } from '../hooks/useLocale'
import { useData } from '../hooks/useData'
import { useSchema } from '../hooks/useSchema'
import { useObserver } from '../hooks/useObserver'
import { Table } from './Table'
import { name } from '../../package.json'

type TMainProps = {
  isCreatable: boolean;
  isSelectable: boolean;
  isRouterEnabled: boolean;
  detailsCell?: TDetailsCell;
  mountPointId?: string;
  mountPointInnerId?: string;
}

const MountPortal = ({ children, mountElement, mountPointInnerId }: TMountPortalProps) => {
  let mountPointInner = document.getElementById(mountPointInnerId);
  if (!mountPointInner) {
    mountPointInner = document.createElement('div');
    mountPointInner.id = mountPointInnerId;
  }
  useEffect(() => {
    mountElement.appendChild(mountPointInner as Node)
    return () => {
      mountElement.removeChild(mountPointInner as Node);
    }
  }, [mountPointInner, mountElement])
  return createPortal(children, mountPointInner);
};

export const Main = ({
  isCreatable,
  isSelectable,
  isRouterEnabled,
  detailsCell,
  mountPointId,
  mountPointInnerId,
}: TMainProps) => {
  const { $t } = useLocale()
  const [isDelConfirmActive, activateDelConfirm] = useState(false)
  const [mountElement, setMountElement] = useState<Element | null>(null)
  const [selectedRows, setSelectedRows] = useState<string[]>([])
  const { data, setData, prepareData } = useData()
  const { eventBus, eventBusID } = useObserver()
  const { schema } = useSchema()

  const selectUnselectAllRows = () => {
    setSelectedRows(
      selectedRows.length === data.length ? [] : data.map(({ id }) => id)
    );
  };

  const selectUnselectSingleRow = (id: string) => {
    setSelectedRows(
      selectedRows.includes(id)
        ? selectedRows.filter((el) => el !== id)
        : [...selectedRows, id]
    );
  };

  const DelConfirmMessage = (
    <div
      className="p-dialog-content"
      style={{ padding: '0 var(--form-padding)' }}
    >{$t('_delSection.message', selectedRows.length)}</div>
  );

  const getDelPayload = () => {
    const selectedRowsSet = new Set(selectedRows);
    return data.filter(({ id }) => selectedRowsSet.has(id));
  };

  const deleteSelectedEntities = async () => {
    activateDelConfirm(false);
    const payload = getDelPayload();
    eventBus.notify(eventBusID, 'deleteEntities', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: payload
    });
    setSelectedRows([]);
  };

  const DelConfirmFooter = (
    <footer css={delConfirmFooterCSS}>
      <Button
        label={$t('_delete')}
        className="p-button-danger"
        onClick={deleteSelectedEntities}
      />
      <Button
        label={$t('_cancel')}
        className="p-button-secondary p-button-outlined"
        onClick={() => activateDelConfirm(false)}
      />
    </footer>
  );

  const openDetails = (entity: TTableRow) => {
    eventBus.notify(eventBusID, 'openDetails', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: entity
    });
  };

  const deletingSection = useMemo(() => (
    selectedRows.length ?
      <div css={deletingSectionCSS}>
        <span>{$t('_selected')} -&nbsp;</span>
        {selectedRows.length}
        <Button
          label={$t('_delete')}
          className="p-button-outlined p-button-danger"
          onClick={() => activateDelConfirm(true)}
        />
      </div> :
      null
  ), [selectedRows, $t]);

  const prepareToCreateEntity = () => {
    const entity: Record<string, any> = { type: schema.entityType };
    openDetails?.(entity);
  };

  const updateTableData = <T,>(payload: PublishPayload<T>) => {
    setData(prepareData(payload.data as unknown as TTableRow[]));
  };

  useEffect(() => {
    if (mountPointId && mountPointInnerId) {
      setMountElement(document.getElementById(mountPointId))
    }
  }, []);
  
  useEffect(() => {
    eventBus.subscribe(eventBusID, 'data:changed', updateTableData)
    return () => {
      eventBus.unsubscribe(eventBusID, 'data:changed');
    }
  }, [eventBus, eventBusID]);

  return (
    <Fragment>
      {mountElement && mountPointInnerId &&
        <MountPortal mountElement={mountElement} mountPointInnerId={mountPointInnerId}>
          <div style={{ display: 'flex', alignItems: 'center' }}>
            {deletingSection && deletingSection}
            {isCreatable &&
              <Button
                label={$t('_createSection.button.label')}
                style={{ marginLeft: 'var(--spacer)' }}
                onClick={prepareToCreateEntity}
              />
            }
          </div>
        </MountPortal>
      }
      <div className="widget-wrapper" css={wrapperCSS}>
        <div css={containerCSS}>
          <Table
            selectedRows={selectedRows}
            isSelectable={isSelectable}
            isRouterEnabled={isRouterEnabled}
            detailsCell={detailsCell}
            selectUnselectAllRows={selectUnselectAllRows}
            selectUnselectSingleRow={selectUnselectSingleRow}
            openDetails={openDetails}
          />
        </div>
        <ConfirmDialog
          header={$t('_delSection.header')}
          footer={DelConfirmFooter}
          message={DelConfirmMessage}
          visible={isDelConfirmActive}
        />
      </div>
    </Fragment>
  )
}

const wrapperCSS = css`
  display: grid;
  grid-template-rows: 1fr;
  overflow: hidden;
`

const containerCSS = css`
  overflow: hidden;
  display: grid;
  grid-template-rows: auto 1fr;
  margin-top: calc(14rem / var(--bfs));
  height: calc(100% - 14rem / var(--bfs));

  .p-scrollpanel-content {
    padding: 0 8px 0 0;
    height: 100%;
  }

  .p-scrollpanel-bar {
    width: 5px !important;
    background: var(--text-color-secondary);
    opacity: 1 !important;

    &-x {
      display: none;
    }
  }
`

const deletingSectionCSS = css`
  display: flex;
  align-items: center;

  & > span {
    color: var(--text-color-secondary);
    font-weight: 500;
    font-size: calc(14rem / var(--bfs));
  }

  .p-button {
    margin-left: var(--spacer);
  }
`

const delConfirmFooterCSS = css`
  display: flex;
`
