import React, { ReactNode, useContext } from 'react';
import { TWidgetArea } from '../types';

interface IAreasContext {
  areas?: TWidgetArea[];
  mountChildren?: Function;
}

interface IAreasProviderProps {
  data: IAreasContext;
  children: ReactNode;
}

const AreasContext = React.createContext<IAreasContext>({});

export const useAreas = () => useContext(AreasContext);

export const AreasProvider = ({ data, children }: IAreasProviderProps) => {
  return (
    <AreasContext.Provider value={data}>
      {children}
    </AreasContext.Provider>
  )
}
