import React from 'react';
import { TTableCellProps } from '../../types';
import { useLocale } from '../../hooks/useLocale';
import { Tooltip } from 'primereact/tooltip';

export const TableCellBoolean = ({ cell, row }: Omit<TTableCellProps, 'colSchema'>) => {
  const { $t } = useLocale()

  return (
    <div
      style={{ textAlign: 'center', flex: 1 }}
      className="boolean-cell"
    >
      {
        row[cell] ?
          <>
            <Tooltip target={`#icon_${row.id}`} />
            <i
              id={`icon_${row.id}`}
              className={`mdi mdi-24px mdi-check`}
              style={{ color: 'var(--primary-color)' }}
              data-pr-tooltip={$t('_yes')}
            />
          </> :
          <>
            <Tooltip target={`#icon_${row.id}`} />
            <i
              id={`icon_${row.id}`}
              className={`mdi mdi-24px mdi-minus`}
              style={{ color: 'var(--text-color-secondary)' }}
              data-pr-tooltip={$t('_no')}
            />
          </>
      }
    </div>
  )
}
