import React, { ReactNode, useContext, useEffect, useMemo, useState } from 'react';
import { TTableRow, TWidgetProps, TWidgetSchema } from '../types';
import { useSchema } from './useSchema';
import { useLocale } from './useLocale';

interface IDataContext {
  data: TTableRow[];
  dataMap: Map<string, TTableRow>;
  auxilaryMetadata: Map<string, TWidgetSchema[]> | null;
  setData: React.Dispatch<React.SetStateAction<TTableRow[]>>;
  prepareData: (data: TTableRow[]) => TTableRow[];
}

interface IDataProviderProps {
  initialData: TTableRow[],
  children: ReactNode,
  auxilaryMetadata: Map<string, TWidgetSchema[]> | null
  prepareComparable: TWidgetProps['prepareComparable']
}

const DataContext = React.createContext<IDataContext>({
  data: [],
  dataMap: new Map(),
  auxilaryMetadata: new Map(),
  setData: () => {},
  prepareData: (data: TTableRow[]) => ([])
});

export const useData = () => useContext(DataContext);

export const DataProvider = ({
  initialData,
  children,
  auxilaryMetadata,
  prepareComparable
}: IDataProviderProps) => {
  const { locale } = useLocale();
  const { schema: { viewTable } } = useSchema();
  const [data, setData] = useState<TTableRow[]>([]);

  const dataMap = useMemo(() => (
    new Map<string, TTableRow>(data.map((row) => [row.id, row]))
  ), [data]);

  const prepareData = (data: TTableRow[]) => {
    return data.reduce<TTableRow[]>((acc, next) => {
      Object.entries(viewTable.columns).forEach(([col, { cell }]) => {
        if (cell.type === 'compare') {
          const compared = prepareComparable(next, col, locale, auxilaryMetadata, cell.config);
          if (compared) {
            next = { ...next, ...compared };
          }
        }
      })
      acc.push(next);
      return acc;
    }, []);
  };

  useEffect(() => {
    setData(prepareData(initialData));
  }, [initialData]);

  return (
    <DataContext.Provider value={{
      data,
      dataMap,
      setData,
      auxilaryMetadata,
      prepareData
    }}>
      {children}
    </DataContext.Provider>
  );
}
