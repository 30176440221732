import React, { useEffect, useRef } from 'react';
import { TTableCellProps, TTableConfigColumn } from '../../types';
import { useAreas } from '../../hooks/useAreas';
import { useData } from '../../hooks/useData';

export const TableCellMount = ({ cell, row, colSchema }: TTableCellProps & { colSchema: TTableConfigColumn }) => {
  const mountingPointRef = useRef<HTMLDivElement | null>(null);
  const { areas, mountChildren } = useAreas()
  const { data } = useData()
  if (!areas || !mountChildren) throw new Error('areas array or mounting function not defined')

  const widgetPropsMap = new Map([
    [
      'streamPlayer',
      { cameraId: data.find(({ id }) => row.id === id)?.cameraId }
    ]
  ])

  useEffect(() => {
    const { current: mountingPoint } = mountingPointRef;
    if (mountingPoint) {
      const area = areas.find(({ name }) => name === colSchema.cell.name)
      if (area) {
        return mountChildren(mountingPoint, area.children.map((child) => ({
          ...child,
          props: { ...row, ...(widgetPropsMap.get(cell) || {}) }
        })));
      }
    }
    return undefined;
  }, [mountChildren, areas]);
  return <div ref={mountingPointRef} />
}
