export const localesEN = {
  _selected: 'Selected',
  _delete: 'Delete',
  _copy: 'Copy',
  _clean: 'Clean',
  _reset: 'Reset',
  _yes: 'Yes',
  _no: 'No',
  _cancel: 'Cancel',
  _empty: '',
  _delSection: {
    header: 'Delete',
    message: 'Delete entities? ({param})',
    confirm: 'Delete'
  },
  _createSection: {
    button: {
      label: 'Add'
    }
  },
  _sorting: {
    disabled: 'Without sorting',
    asc: 'Ascending',
    desc: 'Descending'
  },
  _filter: 'Filter',
  _filterAbsent: 'Filter is not defined',
  _search: 'Search',
  _enumFilterPlaceholder: 'Select items',
  _apply: 'Apply',
  _noResults: 'No data',
  _dateTimeFilterModes: {
    single: 'Single',
    multiple: 'Multiple',
    range: 'Range'
  },
  _dateTimeLocale: {
    toggleButtons: {
      month: 'Month',
      week: 'Week',
      dayBefore: 'Yesterday',
      today: 'Today'
    },
    tooltips: {
      mode: 'Mode'
    }
  }
};
