import React from 'react';

let nextId = 0;
const genElementId = () => `id-${Date.now()}-${nextId++}`;

export const EmptyIcon = ({color}: {color: string}) => {
  const idf0 = genElementId()
  const idf1 = genElementId()
  const id0 = genElementId()
  const id1 = genElementId()
  const id2 = genElementId()
  const id3 = genElementId()
  const id4 = genElementId()
  const id5 = genElementId()
  
  return (
    <svg width="528" height="437" viewBox="0 0 528 437" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.7" filter={`url(#${idf0})`}>
        <path
          d="M398.654 145.032L132.124 251.97L161.303 342.624L240.165 317.24L398.654 145.032Z"
          fill={`url(${id0})`}
        />
      </g>
      <g opacity="0.7" filter={`url(#${idf1})`}>
        <path
          d="M151.295 94.0383L389.946 253.786L342.649 336.445L270.743 295.3L151.295 94.0383Z"
          fill={`url(#${id1})`}
        />
      </g>
      <path
        opacity="0.8"
        d="M364.903 163.404L165.176 243.539L176.109 277.505L187.042 311.471L246.137 292.45L364.903 163.404Z"
        fill={`url(#${id2})`}
      />
      <path
        opacity="0.8"
        d="M179.543 125.191L358.379 244.899L322.936 306.84L269.052 276.009L179.543 125.191Z"
        fill={`url(#${id3})`}
      />
      <path opacity="0.8" d="M364.903 163.404L187.042 311.471L246.137 292.45L364.903 163.404Z" fill={`url(#${id4})`} />
      <path opacity="0.8" d="M179.543 125.191L322.936 306.84L269.052 276.009L179.543 125.191Z" fill={`url(#${id5})`} />
      <defs>
        <filter
          id={idf0}
          x="31.9561"
          y="51.0322"
          width="496.045"
          height="385.591"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur" />
        </filter>
        <filter
          id={idf1}
          x="0"
          y="0.0380859"
          width="493.944"
          height="430.406"
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix" />
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur" />
        </filter>
        <linearGradient id={id0} x1="489.63" y1="102.18" x2="146.687" y2="349.214" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
        <linearGradient id={id1} x1="71.1476" y1="33.2969" x2="355.587" y2="345.916" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
        <linearGradient id={id2} x1="433.077" y1="131.292" x2="176.089" y2="316.41" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="0.816895" stopColor={color} stopOpacity="0.183105" />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
        <linearGradient id={id3} x1="119.484" y1="79.6735" x2="332.631" y2="313.938" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
        <linearGradient id={id4} x1="136.343" y1="268.881" x2="205.695" y2="338.976" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
        <linearGradient id={id5} x1="381.347" y1="275.656" x2="298.998" y2="329.893" gradientUnits="userSpaceOnUse">
          <stop stopColor={color} />
          <stop offset="1" stopColor={color} stopOpacity="0" />
        </linearGradient>
      </defs>
    </svg>
  );
};