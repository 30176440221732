/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useMemo, useRef } from 'react';
import { TDetailsCell, TTableConfig, TTableRow } from '../types';
import { useSchema } from '../hooks/useSchema';
import { TableRow } from './TableRow';

type TTableBodyProps = {
  rows: TTableRow[];
  order: TTableConfig['order'];
  columns: TTableConfig['columns'];
  isSwipable: boolean;
  isSelectable: boolean;
  detailsCell?: TDetailsCell;
  selectedRows: string[];
  selectUnselectRow: (id: string) => void;
  detailsOpen: (rowID: string) => void;
}

export const TableBody = ({
  rows,
  order,
  columns,
  isSwipable,
  isSelectable,
  detailsCell,
  selectedRows,
  selectUnselectRow,
  detailsOpen
}: TTableBodyProps) => {
  const tbodyRef = useRef<HTMLTableSectionElement>(null);
  const { schema } = useSchema();

  const preparedRows = useMemo(() => {
    const { viewTable: { groups }, isGrouped } = schema;
    const activeGroups = groups?.filter(({ isActive }) => isActive) || []

    if (!groups?.length || !isGrouped) return rows;

    const groupedRows = rows
      .reduce<Array<TTableRow | Map<number, TTableRow>>>((acc, next) => {
        if (!acc.length) {
          acc.push(next);
        } else {
          const prev = acc[acc.length - 1];
          if (prev instanceof Map) {
            const isAppropriate = activeGroups.every(({ field }) => (
              prev?.get(0)?.[field] === next[field]
            ));

            if (isAppropriate) {
              prev.set(prev.size, next);
            } else {
              acc.push(next);
            }
          } else {
            const isAppropriate = activeGroups.every(({ field }) => (
              prev?.[field] === next[field]
            ));

            if (isAppropriate) {
              acc[acc.length - 1] = new Map([
                [0, prev],
                [1, next]
              ]);
            } else {
              acc.push(next);
            }
          }
        }
        
        return acc
      }, [])
      .map((row) => row instanceof Map ? Array.from(row.values()) : row);

    return groupedRows;
  }, [rows, schema]);

  return (
    <tbody
      className="wtable__body"
      css={tbodyCSS}
      ref={tbodyRef}
    >
      {preparedRows.map((row, index) => (
        <TableRow
          key={`${Array.isArray(row) ? row.length : row.id}-${index}`}
          selectUnselectRow={selectUnselectRow}
          selectedRows={selectedRows}
          isSelectable={isSelectable}
          detailsCell={detailsCell}
          detailsOpen={detailsOpen}
          isSwipable={isSwipable}
          columns={columns}
          order={order}
          row={row}
        />
      ))}
    </tbody>
  )
}

const tbodyCSS = css`
  color: var(--text-color-secondary);
`

