import { useLayoutEffect, useState } from 'react';

export const useWindowSize = () => {
  const [winWidth, setWinWidth] = useState(0);
  const [winHeight, setWinHeight] = useState(0);

  const updateWinSize = () => {
    setWinWidth(window.innerWidth);
    setWinHeight(window.innerHeight);
  }

  useLayoutEffect(() => {
    window.addEventListener('resize', updateWinSize);
    updateWinSize();
    return () => window.removeEventListener('resize', updateWinSize);
  }, []);

  return { winWidth, winHeight };
}
