/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { TTableCellProps } from '../../types';

export const TableCellJSON = ({ cell, row }: TTableCellProps) => {
  const data = typeof row[cell] === 'string' ? JSON.parse(row[cell]) : row[cell]
  const jsonString = JSON.stringify(data, null, 2)

  return (
    <div css={jsonCellCSS}>
      <pre>
        <code>{jsonString}</code>
      </pre>
    </div>
  )
}

const jsonCellCSS = css`
  code {
    white-space: pre-wrap;
  }
`
