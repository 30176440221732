export class ColorBuilder {
  static rgbToHex(rgb: [number, number, number]) {
    return `#${rgb
      .map((x) => {
        const hex = x.toString(16);
        return hex.length === 1 ? `0${hex}` : hex;
      })
      .join('')}`;
  }

  static getColorValues(rawVar: string) {
    return JSON.parse(`[${getComputedStyle(document.body).getPropertyValue(rawVar)}]`)
  }
}