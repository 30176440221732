import { Locales } from '../types';
import { localesRU } from './locales.ru';
import { localesEN } from './locales.en';

export const locales: Locales = {
  ru: localesRU,
  en: localesEN
}

export type ILocale = typeof locales;
