import React from 'react';
import { TRootProps } from './types'
import { LocaleProvider } from './hooks/useLocale';
import { AreasProvider } from './hooks/useAreas';
import { SchemaProvider } from './hooks/useSchema';
import { DataProvider } from './hooks/useData';
import { ObserverProvider } from './hooks/useObserver';
import { FiltersProvider } from './hooks/useFilters';
import { Main } from './components/Main';;

export const Root = ({
  props: {
    data,
    mountPointId,
    mountPointInnerId,
    prepareComparable,
    auxilaryMetadata,
    schema,
    detailsCell,
    eventBusID,
    eventBus,
    filters,
    sorting,
    isRouterEnabled = true,
    isCreatable = false,
    isSelectable = false,
  },
  areas,
  mountChildren,
}: TRootProps) => {
  return (
    <LocaleProvider value={schema.locales}>
      <AreasProvider data={{ areas, mountChildren }}>
        <SchemaProvider initialSchema={schema}>
          <DataProvider
            initialData={data}
            auxilaryMetadata={auxilaryMetadata}
            prepareComparable={prepareComparable}
          >
            <ObserverProvider data={{ eventBus, eventBusID }}>
              <FiltersProvider config={{ filters, sorting }}>
                <Main
                  isCreatable={isCreatable}
                  isSelectable={isSelectable}
                  isRouterEnabled={isRouterEnabled}
                  mountPointId={mountPointId}
                  mountPointInnerId={mountPointInnerId}
                  detailsCell={detailsCell}
                />
              </FiltersProvider>
            </ObserverProvider>
          </DataProvider>
        </SchemaProvider>
      </AreasProvider>
    </LocaleProvider>
  )
}
