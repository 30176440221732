/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { ColorBuilder } from '../utils/ColorBuilder'
import { useLocale } from '../hooks/useLocale';
import { EmptyIcon } from './icons/EmptyIcon'

export const EmptySection = () => {
  const { $t } = useLocale()
  const errorColor = ColorBuilder.rgbToHex(ColorBuilder.getColorValues('--error-color-raw'));

  return (
    <div css={emptySectionWrapperCSS}>
      <div css={emptySectionCSS}>
        <EmptyIcon color={errorColor} />
        <span>{$t('_noResults')}</span>
      </div>
    </div>
  )
}

const emptySectionWrapperCSS = css`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
`

const emptySectionCSS = css`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  svg {
    width: 100%;
    height: 100%;
    position: absolute;
  }

  span {
    font-size: var(--font-size-lg);
    font-weight: 500;
    color: var(--text-color);
    transform: translateY(-500%);
    position: absolute;
    bottom: 0;

      @media (max-height: 900px) {
        bottom: -70px;
    }
  }
`
