import React, { useEffect, useRef, useState, useMemo } from 'react';
import { PublishPayload } from '@netvision/lib-types-frontend';
import { TDateTimeProps } from '../../types';
import { useObserver } from '../../hooks/useObserver';
import { useAreas } from '../../hooks/useAreas';
import { useFilters } from '../../hooks/useFilters';
import { useWindowSize } from '../../hooks/useWindowSize';
import { TDateTimeFilterProps } from '../cells/TableHeadCell';
import { name } from '../../../package.json';

export const DateTimeFilter = ({ columnKey, sortingField, closeFilterPanel, dateTimeProps }: TDateTimeFilterProps) => {
  const calendarRef = useRef<HTMLDivElement | null>(null);
  const { winHeight } = useWindowSize();
  const { eventBus, eventBusID } = useObserver();
  const { areas, mountChildren } = useAreas();
  const { filters, setFilters } = useFilters();
  const [dateValue, setDateValue] = useState<number[] | null>(null);
  const [props, setProps] = useState<TDateTimeProps>({
    min: -Infinity,
    max: Infinity,
    selectionMode: undefined,
    ...(dateTimeProps || {})
  });

  const calendarStyles = useMemo(() => ({
    maxHeight: winHeight < 800 ? '45vh' : '65vh',
    overflow: 'auto',
    padding: 0
  }), [winHeight]);

  const submitFormFilter = (payload: PublishPayload<{
    mode: TDateTimeProps['selectionMode']
    values: number[]
  }>) => {
    eventBusID && eventBus.notify(eventBusID, 'filterUpdate', {
      publisher: name,
      occurrenceTime: Date.now(),
      data: {
        payload: {
          [columnKey]: {
            mode: payload.data.mode,
            values: payload.data.values
          }
        },
        sortingField
      }
    })

    setFilters((prev) => {
      const prevTimeKey = Array.from(prev?.keys() || []).find(key => key.startsWith(columnKey))
      prevTimeKey && prev?.delete(prevTimeKey)

      const newFilters = new Map(prev?.entries() || [])

      payload.data.values.length
        ? newFilters.set(`${columnKey}.${payload.data.mode}`, payload.data.values.map(String).join(','))
        : newFilters.delete(`${columnKey}.${payload.data.mode}`)
      return newFilters
    })

    closeFilterPanel()
  };

  useEffect(() => {
    if (!filters) return
    const parsedKey = [...filters.keys()].find((key) => key.startsWith(columnKey))
    const filterValue = parsedKey && filters.get(parsedKey)
    if (filterValue && parsedKey && typeof filterValue === 'string') {
      const [_, mode] = parsedKey.split('.')
      if (mode) {
        setProps({ ...props, selectionMode: mode as Required<TDateTimeProps>['selectionMode'] })
      }
      setDateValue(filterValue.split(',').map(Number))
    } else {
      setProps({ ...props, selectionMode: 'single' })
      setDateValue([])
    }
  }, [calendarRef]);

  useEffect(() => {
    if (eventBusID) {
      eventBus.subscribe(eventBusID, 'hideCalendar', closeFilterPanel)
      // @ts-ignore
      eventBus.subscribe(eventBusID, 'applyDateTime', submitFormFilter)
    }

    return () => {
      if (eventBusID) {
        eventBus.unsubscribe(eventBusID, 'hideCalendar', closeFilterPanel)
        // @ts-ignore
        eventBus.unsubscribe(eventBusID, 'applyDateTime', submitFormFilter)
      }
    }
  }, [eventBus, eventBusID]);

  useEffect(() => {
    const { current: mountingPoint } = calendarRef
    if (mountingPoint && mountChildren && props.selectionMode && dateValue) {
      const area = areas?.find(({ name }) => name === 'calendar')
      if (area) {
        return mountChildren(mountingPoint, area.children.map((child) => ({
          ...child,
          props: {
            ...child.props,
            parentName: name,
            selectionMode: props.selectionMode,
            eventBusID,
            style: calendarStyles,
            initialDateTime: dateValue
          }
        })))
      }
    }
    return undefined
  }, [mountChildren, areas, props.selectionMode, dateValue])

  return <div ref={calendarRef} />
}
