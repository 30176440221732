import React from 'react';
import { TTableCellProps } from '../../types';

export const TableCellIcon = ({ cell, row }: Omit<TTableCellProps, 'colSchema'>) => {
  return (
    <div style={{ textAlign: 'center', flex: 1 }}>
      <i
        className={`mdi mdi-24px ${row[cell]?.iconClass || 'mdi-square-outline'}`}
        style={{ color: row[cell]?.color || 'var(--primary-color)' }}
      />
    </div>
  )
}
