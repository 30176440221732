/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { Fragment } from 'react';
import { TTableCellProps } from '../../types';

export const TableCellURL = ({ cell, row }: Omit<TTableCellProps, 'colSchema'>) => {
  return (
    <Fragment>
      <a
        href={/^((http|https):\/\/)/.test(row[cell]) ? row[cell] : `https://${row[cell]}`}
        target="_blank"
        css={linkCSS}
      >{row[cell]}</a>
    </Fragment>
  )
}

const linkCSS = css`
  color: var(--text-color-secondary);
`
