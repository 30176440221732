/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx, css } from '@emotion/react';
import { useRef, useState } from 'react';
import { Dialog } from 'primereact/dialog';
import { ProgressSpinner } from 'primereact/progressspinner';
import { TableCellMount } from './TableCellMount';
import { TTableCellProps } from '../../types';
import { TypeChecker } from '../../utils/TypesChecker';

export const TableCellPreview = ({ cell, row, colSchema }: TTableCellProps) => {
  const { isURL, isBlobUrl } = TypeChecker;
  const cellRef = useRef<HTMLTableCellElement>(null);
  const imageElement = useRef<HTMLImageElement>(null);
  const [isPreviewOpen, setIsPreviewOpen] = useState(false);

  const PreviewPlaceholderEmpty = () => (
    <svg width="100" height="64" viewBox="80 180 520 113" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g opacity="0.4" filter="url(#filter0_f)">
        <path d="M491.841 160.593L143.784 300.241L181.889 418.624L284.873 385.476L491.841 160.593Z"
          fill="url(#paint0_linear)"></path>
      </g>
      <g opacity="0.4" filter="url(#filter1_f)">
        <path d="M168.82 94.0001L480.471 302.611L418.707 410.555L324.805 356.825L168.82 94.0001Z"
          fill="url(#paint1_linear)"></path>
      </g>
      <path opacity="0.5"
        d="M447.767 184.583L186.947 289.23L201.225 333.585L215.502 377.941L292.673 353.101L447.767 184.583Z"
        fill="url(#paint2_linear)"></path>
      <path opacity="0.8" d="M205.71 134.682L439.249 291.007L392.966 371.895L322.599 331.632L205.71 134.682Z"
        fill="url(#paint3_linear)"></path>
      <path opacity="0.8" d="M447.767 184.583L215.502 377.941L292.673 353.101L447.767 184.583Z" fill="url(#paint4_linear)">
      </path>
      <path opacity="0.8" d="M205.71 134.682L392.966 371.895L322.599 331.632L205.71 134.682Z" fill="url(#paint5_linear)">
      </path>
      <defs>
        <filter id="filter0_f" x="41.7295" y="66.5925" width="590.271" height="446.031" filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur"></feGaussianBlur>
        </filter>
        <filter id="filter1_f" x="0" y="0" width="587.527" height="504.555" filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB">
          <feFlood floodOpacity="0" result="BackgroundImageFix"></feFlood>
          <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"></feBlend>
          <feGaussianBlur stdDeviation="47" result="effect1_foregroundBlur"></feGaussianBlur>
        </filter>
        <linearGradient id="paint0_linear" x1="610.645" y1="104.632" x2="162.802" y2="427.231"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3C3C"></stop>
          <stop offset="1" stopColor="#FF3C3C" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint1_linear" x1="64.1573" y1="14.6789" x2="435.602" y2="422.923"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3C3C"></stop>
          <stop offset="1" stopColor="#FF3C3C" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint2_linear" x1="536.795" y1="142.648" x2="201.199" y2="384.391"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3C3C"></stop>
          <stop offset="0.816895" stopColor="#FF3C3C" stopOpacity="0.183105"></stop>
          <stop offset="1" stopColor="#FF3C3C" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint3_linear" x1="127.28" y1="75.2416" x2="405.626" y2="381.163"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3C3C"></stop>
          <stop offset="1" stopColor="#FF3C3C" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint4_linear" x1="149.295" y1="322.324" x2="239.861" y2="413.859"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3C3C"></stop>
          <stop offset="1" stopColor="#FF3C3C" stopOpacity="0"></stop>
        </linearGradient>
        <linearGradient id="paint5_linear" x1="469.244" y1="331.172" x2="361.704" y2="401.999"
          gradientUnits="userSpaceOnUse">
          <stop stopColor="#FF3C3C"></stop>
          <stop offset="1" stopColor="#FF3C3C" stopOpacity="0"></stop>
        </linearGradient>
      </defs>
    </svg>
  )

  const PreviewPlaceholderLoading = () => (
    <div css={loadingPreview}>
      <ProgressSpinner style={{ width: 'var(--spacer)', height: 'var(--spacer)' }} />
    </div>
  )

  return (
    <div css={previewCellCSS} ref={cellRef}>
      {typeof row[cell] === 'undefined'
        ? <PreviewPlaceholderLoading />
        : isURL(row[cell]) || isBlobUrl(row[cell])
            ? <div
                css={previewWrapperCSS}
                onClick={() => setIsPreviewOpen(true)}
                className={isPreviewOpen ? 'opened' : ''}
              >
                <img src={row[cell]} css={previewImageCSS} ref={imageElement} />
                <i className="mdi mdi-24px mdi-magnify-plus-outline" />
              </div>
            : <PreviewPlaceholderEmpty />
      }
      <Dialog
        header={row.title || row.name || ''}
        visible={isPreviewOpen}
        dismissableMask={true}
        css={modalPreview}
        onHide={() => setIsPreviewOpen(false)}
      >
        {isURL(row[cell]) || isBlobUrl(row[cell])
          ? <img src={row[cell]} className="media-preview" />
          : <TableCellMount cell={cell} row={row} colSchema={colSchema} />
        }
      </Dialog>
    </div>
  )
}

const previewCellCSS = css`
  width: calc(100rem / var(--bfs));
  max-width: calc(100rem / var(--bfs));
  padding-top: 0 !important;
  padding-bottom: 0 !important;
  text-align: center;
  flex: 1;
`

const previewWrapperCSS = css`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;

  &.opened {
    &:before {
      opacity: 1;
    }

    .mdi-magnify-plus-outline {
      opacity: 1;
      color: var(--text-color);
    }
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 1;
    opacity: 0;
    background-color: rgba(0, 0, 0, 0.5);
    transition: opacity var(--transition-duration) ease;
  }

  .mdi-magnify-plus-outline {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    opacity: 0;
    transition: opacity var(--transition-duration) ease;
  }

  &:hover {
    &:before {
      opacity: 1;
    }

    .mdi-magnify-plus-outline {
      opacity: 1;
    }
  }
`

const previewImageCSS = css`
  width: 100%;
  height: calc(64rem / var(--bfs));
  object-fit: cover;
  display: block;
  border-radius: calc(var(--border-radius) / 3);
`

const modalPreview = css`
  width: 50%;
  min-height: 15rem;
  overflow: hidden;

  .media-preview {
    width: 100%;
    display: block;
  }
`

const loadingPreview = css`
  height: calc(64rem / var(--bfs));
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(var(--secondary-color-alt-2-raw), 0.5);
  border-radius: var(--border-radius);
`
