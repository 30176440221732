import React, { ReactNode, useContext } from 'react';
import { GlobalEventBus } from '@netvision/lib-types-frontend';

interface IObserverContext {
  eventBusID: string
  eventBus: GlobalEventBus
}

interface IObserverProviderProps {
  data: IObserverContext;
  children: ReactNode;
}

const ObserverContext = React.createContext<IObserverContext>(null!);

export const useObserver = () => useContext(ObserverContext)

export const ObserverProvider = ({ data, children }: IObserverProviderProps) => {
  return (
    <ObserverContext.Provider value={data}>
      {children}
    </ObserverContext.Provider>
  )
}
