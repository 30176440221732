import React, { ReactNode, useContext, useEffect, useState } from 'react';
import { LocaleCodes, TWidgetSchema } from '../types';
import { addLocale } from 'primereact/api';
import { locales } from '../locales';
import { get, merge } from 'lodash-es';

interface ILocaleContext {
  locale: LocaleCodes;
  setLocale: (locale: LocaleCodes) => void;
  $t: <T,>(path: string, ...rest: Array<string | number>) => T | string;
}

interface ILocaleProviderProps {
  value?: TWidgetSchema['locales'];
  children: ReactNode;
}

const LocaleContext = React.createContext<ILocaleContext>({
  locale: 'ru',
  setLocale: (locale: LocaleCodes) => {},
  $t: <T,>(path: string, ...rest: Array<string | number>): T | string => path,
});

export const useLocale = () => useContext(LocaleContext);

export const LocaleProvider = ({ value, children }: ILocaleProviderProps) => {
  value = merge(locales, value)
  const [isFirstRender, setIsFirstRender] = useState(true)
  const [locale, setLocale] = useState<LocaleCodes>('ru')
  const $t = <T,>(path: string, ...rest: Array<string | number>): T | string => {
    const data = get(value, `${locale}.${path}`)
    if (typeof data !== 'string') return data as T
    const message: string = String(data)
    return !rest.length ?
      message :
      message.split(' ').reduce((acc, next) => {
        acc.push(
          next.includes('{param}') ?
            next.replace('{param}', String(rest.shift())) :
            next
        )
        return acc
      }, [] as Array<string | number>).join(' ')
  }

  useEffect(() => {
    if (isFirstRender) {
      setLocale(localStorage.getItem('netvision:locale') as LocaleCodes || 'ru')
      addLocale(locale, get(value, `${locale}._dateTimeLocale`) as object)
      setIsFirstRender(false)
    }
  }, [isFirstRender, locale])

  useEffect(() => {
    if (!isFirstRender && locale) {
      localStorage.setItem('netvision:locale', locale)
      addLocale(locale, get(value, `${locale}._dateTimeLocale`) as object)
    }
  }, [locale])

  return (
    <LocaleContext.Provider value={{ $t, locale, setLocale }}>
      {children}
    </LocaleContext.Provider>
  )
};
